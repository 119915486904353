import React from 'react'

import { Helmet } from 'react-helmet'

import HeaderFull from '../components/header-full'
import BlogCard from '../components/blog-card'
import Footer from '../components/footer'
import './road-map.css'

const ROADMAP = (props) => {
  return (
    <div className="roadmap-container">
      <Helmet>
        <title>ROAD-MAP - Elon Piece on SOL</title>
        <meta property="og:title" content="ROAD-MAP - Elon Piece on SOL" />
      </Helmet>
      <HeaderFull></HeaderFull>
      <div className="roadmap-blog-posts">
        <h1 className="roadmap-text HeadingTwo">
          <span>ROAD MAP</span>
          <br></br>
        </h1>
        <div className="roadmap-container1">
          <BlogCard></BlogCard>
          <BlogCard
            imageSrc="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/color-clock.jpg"
            description="Venture investment in U.S. startups rose sequentially in the second quarter of 2017, boosted by large, ate-stage financings"
          ></BlogCard>
          <BlogCard
            title="MateLabs machine learning"
            imageSrc="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/color-wall.jpg"
            description="If you’ve ever wanted to train a machine learning model and integrate it with IFTTT, you now can with"
          ></BlogCard>
          <div className="roadmap-container2">
            <div className="roadmap-card">
              <div className="roadmap-container3">
                <h1 className="roadmap-text3">Flexible work hours</h1>
                <span className="roadmap-text4">
                  <span className="roadmap-text5">
                    Rather than worrying about switching offices every couple
                    years, you stay in the same place.
                  </span>
                </span>
                <div className="roadmap-container4">
                  <button className="roadmap-button button">
                    <span className="roadmap-text6">Read more</span>
                    <svg viewBox="0 0 1024 1024" className="roadmap-icon">
                      <path d="M512 170l342 342-342 342-60-60 238-240h-520v-84h520l-238-240z"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="roadmap-container5"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default ROADMAP
