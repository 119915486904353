import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import PrimaryPinkButton from '../components/primary-pink-button'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Elon Piece on SOL</title>
        <meta property="og:title" content="Elon Piece on SOL" />
      </Helmet>
      <Header></Header>
      <div className="home-hero">
        <div className="home-container01">
          <div className="home-card">
            <h1 className="home-text">Set Sail with</h1>
            <h1 className="home-text01">Elon Piece</h1>
            <img
              alt="image"
              src="/5a2fdaa0d65a1921-removebg-preview_1-700h.webp"
              className="home-image"
            />
            <span className="home-text02">
              &quot;Elon Piece&quot; is your ticket aboard the wildest, wackiest
              crypto adventure ever! Imagine the high seas of &quot;One
              Piece&quot; mashed up with the rocket-fueled brain of Elon
              Musk—yes, it&apos;s as crazy as it sounds.
            </span>
            <div className="home-container02">
              <div className="home-container03">
                <a
                  href="https://www.pump.fun/5KZq9cbQPd4WZ1ch7XbwPFH1bZhCSLoBvorVs7vLDnHw"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link"
                >
                  <PrimaryPinkButton
                    button="BUY $ElonP"
                    rootClassName="primary-pink-button-root-class-name"
                    className="home-component1"
                  ></PrimaryPinkButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img alt="image" src="/4-1500w.webp" className="home-image1" />
      <section className="home-testimonials">
        <div className="home-container04">
          <div className="home-container05">
            <div className="home-container06">
              <h1 className="home-text03">
                <span>Join the Pirate Crew</span>
                <br></br>
              </h1>
              <img
                alt="image"
                src="https://i.ibb.co/Bf2sKqZ/wanted-poster-1.png"
                className="home-image2"
              />
              <p className="home-text06">
                <span className="Body">
                  Ready to hoist the sails and join the crew? Become an honorary
                  member of our Pirate Crew today!
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br className="Body"></br>
                <br className="Body"></br>
                <br></br>
                <br></br>
                <br></br>
              </p>
              <div className="home-container07">
                <a
                  href="https://twitter.com/Elonpiece/with_replies"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link1"
                >
                  <img
                    alt="image"
                    src="https://svgur.com/i/15ru.svg"
                    className="home-image3"
                  />
                </a>
                <a
                  href="https://t.me/ElonPiece"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link2"
                >
                  <img
                    alt="image"
                    src="https://svgur.com/i/15sD.svg"
                    className="home-image4"
                  />
                </a>
              </div>
              <div className="home-container08"></div>
            </div>
          </div>
          <div className="home-container09">
            <div className="home-logos"></div>
          </div>
        </div>
        <img alt="image" src="/bottom.svg" className="home-bottom-wave-image" />
        <img alt="image" src="/waves-white.svg" className="home-image5" />
        <img alt="image" src="/top.svg" className="home-top-wave-image" />
      </section>
      <div className="home-container10">
        <div className="home-container11"></div>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default Home
