import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-container">
        <div className="footer-container1">
          <h1 className="footer-text">ELON PIECE</h1>
          <h1 className="">Copyright © 2024</h1>
        </div>
        <div className="footer-container2">
          <img
            alt="image"
            src="https://upload.wikimedia.org/wikipedia/it/2/2c/One_Piece_Logo.svg"
            className="footer-image"
          />
        </div>
        <img
          alt="image"
          src="https://cryptologos.cc/logos/solana-sol-logo.svg?v=032"
          className="footer-image1"
        />
      </div>
      <img alt="image" src="/waves-white.svg" className="footer-image2" />
    </footer>
  )
}

Footer.defaultProps = {
  rootClassName: '',
}

Footer.propTypes = {
  rootClassName: PropTypes.string,
}

export default Footer
